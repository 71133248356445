import { Component } from 'solid-js'
import { EmptyStateProps } from '~/components/empty-state/empty-state.interfaces'
import styles from '~/components/empty-state/empty-state.module.scss'

const EmptyState: Component<EmptyStateProps> = ({ label, description, border=false, ...props }) => {
  const Icon = props.icon
  const containerStyles = {
    [styles.container]: true,
    [styles.containerWithBorder]: border
  }

  return (
    <div classList={containerStyles}>
      <Icon class={styles.icon} />
      <div class={styles.label}>
        {label}
      </div>
      {description && (
        <div class={styles.description}>
          {description}
        </div>
      )}
    </div>
  )
}

export default EmptyState
